import { Link } from "gatsby";
import React from "react";
import * as Animate from "react-reveal";

export default function StepCard({ step }) {
  const { id, caption, image, steps } = step;
  const direction = id % 2 === 1 ? true : false;

  return (
    <div
      className={`flex flex-col gap-y-8 md:flex-row justify-between items-center  px-4 lg:p-6 rounded-lg`}
      style={{
        boxShadow: "0px 31.0344px 39.9014px rgba(224, 224, 224, 0.25)",
      }}>
      <Animate.Fade bottom>
        <div
          className={`${
            direction ? "order-2" : "order-1"
          } flex flex-col px-6 lg:px-8 pt-6 pb-8 lg:pt-8 lg:pb-10 gap-y-2 bg-[#fff] rounded-md md:w-[45%]`}
          style={{ boxShadow: "0px 0px 25.9355px rgba(8, 160, 92, 0.15)" }}>
          <span className="w-fit bg-cstm-green text-[#fff] text-sm rounded py-2 px-4 font-semibold">
            {id}
          </span>
          <h4 className="mt-2 mb-2 text-lg font-semibold">{caption}</h4>

          <ul className="flex flex-col gap-y-4 list-disc list-outside px-3  font-normal text-sm lg:text-sm-15 leading-6 lg:leading-7 text-cstm-black indent-px">
            {steps.map((item) => {
              return <>{item}</>;
            })}
          </ul>

          {id === 2 && (
            <Link
              className="flex justify-center text-white bg-cstm-green text-sm lg:text-base font-medium px-8 py-4 md:px-6 md:py-4 rounded-lg transition-all ease-in-out hover:shadow-lg mt-5"
              to="https://forms.gle/MERGpk1r5hs8jDo56"
              target="_blank"
              rel="noreferrer noopener">
              Get Started Now
            </Link>
          )}
        </div>

        <div className={`md:w-[35%] ${direction ? "order-1" : "order-2"}`}>
          <img className="w-full rounded-lg" src={image} alt={`step-${id}`} />
        </div>
      </Animate.Fade>
    </div>
  );
}
