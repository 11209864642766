import React from "react";
import StepCard from "./StepCard";
import Image1 from "../../assets/images/pii/male.png";
import Image3 from "../../assets/images/pii/female.png";

const paymentSteps = [
  {
    id: 1,
    image: Image1,
    caption: "What you need to know about our lease to own payment plan",
    steps: [
      <li>
        You can get the Reeddi Energy product on a 3 to 12 months financing plan
        with an affordable interest rate.
      </li>,
      <li>
        This option requires you to pay as low as 15% of the product cost
        upfront. While the balance of the product cost is spread over 3 to 12
        months in installments, as desired by the customer.
      </li>,
    ],
  },

  {
    id: 2,
    image: Image3,
    caption: "Make your request",
    steps: [
      <li>
        If you are interested in this payment option, kindly fill the form
        below. After submitting the form, our sale representative will reach out
        to you.
      </li>,
    ],
  },
];

export default function PaymentSteps() {
  return (
    <section className="w-full mx-auto pt-16 pb-16 lg:pb-24">
      <div className="container px-4 mx-auto w-full lg:w-10/12">
        <div className="flex flex-col gap-y-16 md:gap-y-24">
          {paymentSteps.map((step) => {
            return <StepCard key={step.id} step={step} />;
          })}
        </div>
      </div>
    </section>
  );
}
