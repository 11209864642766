import React from "react";
import Tag from "../Tag";
import * as Animate from "react-reveal";

export default function Jumbotron() {
  return (
    <main className="pt-12 lg:pt-16 h-[80%] bg-cover bg-center">
      <div className="w-11/12 m-auto py-16 max-w-7xl">
        <div className="w-full  flex flex-col justify-center items-center gap-y-4 md:gap-y-8">
          <Animate.Fade bottom>
            <Tag text="Pay in Installments" />
            <h1 className="text-3xl font-semibold text-center leading-tight md:text-4xl w-[90%] md:w-[90%] max-w-[900px] lg:text-5xl lg:leading-tight capitalize">
              Enjoy a flexible payment plan at an affordable interest rate!
            </h1>
            <p className="text-cstm-grey text-center text-sm w-[90%] md:text-sm lg:text-base mb-2 capitalize">
              Pay for your Reeddi Energy Products in installments using these
              simple steps 🚀
            </p>
          </Animate.Fade>
        </div>
      </div>
    </main>
  );
}
